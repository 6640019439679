<template>
  <div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-header">
                <h4>العمليات</h4>
            </div>
            <div class="card-body table-responsive">
                <table class="table table-hover table-striped">
                    <thead>
                        <th>
                            ID
                        </th>
                        <th>
                            تاريخ الانشاء
                        </th>
                        <th>
                            العدد الاجمالي
                        </th>
                        <th>
                            المتبقي
                        </th>
                        <th>
                            الحالة
                        </th>
                        <th>
                            آخر رسالة
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="(p, index) in list" :key="index * 2">
                            <td>
                                {{ p.id }}
                            </td>
                            <td>
                                {{ p.date }}
                            </td>
                            <td>
                                {{ new Intl.NumberFormat().format(p.numbers_left.length + p.numbers_sent.length) }}
                            </td>
                            <td>
                                <strong>
                                    {{ new Intl.NumberFormat().format(p.numbers_left.length) }}
                                </strong>
                                <div class="progress">
                                    <div class="progress-bar" style="background: green;" role="progressbar" :style="`width: ${((p.numbers_sent.length) / (p.numbers_sent.length + p.numbers_left.length)) * 100}%`" :aria-valuenow="((p.numbers_sent.length) / (p.numbers_sent.length + p.numbers_left.length)) * 100" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </td>
                            <td>
                                <span v-if="p.status == 0" class="badge bg-warning btn-sm">
                                    <i class="fa fa-clock-o"></i>
                                    في الانتظار
                                </span>
                                <span v-if="p.status == 1" class="badge bg-success btn-sm">
                                    <i class="fa fa-clock-o"></i>
                                    جاري الإرسال
                                </span>
                                <span v-if="p.status == 2" class="badge bg-danger btn-sm">
                                    <i class="fa fa-clock-o"></i>
                                    متوقف
                                </span>
                                <span v-if="p.status == 3" class="badge bg-secondary btn-sm">
                                    <i class="fa fa-check"></i>
                                    انتهت العملية
                                </span>
                            </td>
                            <td v-html="p.last_message">
                            </td>
                            <td>
                                <b-dropdown
                                    id="dropdown-1"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    text="خيارات"
                                    variant="primary"
                                    size="sm"
                                >
                                    <b-dropdown-item @click="startOperation(p._id)" v-if="p.status != 1">
                                        <span class="text-success">
                                            <i class="fa fa-check"></i>
                                            تشغيل
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item class="text-danger" @click="stopOperation(p._id)" v-if="p.status == 1">
                                        <span class="text-danger">
                                            <i class="fa fa-times"></i>
                                            ايقاف
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="deleteOperation(p._id)">
                                        <i class="fa fa-trash"></i>
                                        حذف
                                    </b-dropdown-item>
                                </b-dropdown>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="col-12 text-center g" v-if="loading">
                    <img
                    :src="require('@/assets/images/loading.svg')"
                    style="width: 150px; height: 150px"
                    alt=""
                    />
                    <br><br>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BDropdown,
        BDropdownItem,
    },
    directives: {
        Ripple,
    },
    data(){
        return {
            loading: true,
            list: [],
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    created(){
        this.getList()
    },
    methods: {
        getList(){
            var g = this;
            $.post(marketing + "/list", {
            jwt: this.user.jwt,
            }).then(function(a){
                g.list = a.response
                g.loading = false
                setTimeout(() => {
                    g.getList()
                }, 5000);
            }).catch(function(){
                setTimeout(() => {
                    g.getList()
                }, 5000);
                g.loading = false
            })
        },
        startOperation(id){
            var g =this;
            $.post(marketing + "/operation-status", {
            jwt: this.user.jwt,
            id: id,
            status: 1
            }).then(function(a){
                location.reload()
            })
        },
        stopOperation(id){
            var g =this;
            $.post(marketing + "/operation-status", {
            jwt: this.user.jwt,
            id: id,
            status: 2
            }).then(function(a){
                location.reload()
            })
        },
        deleteOperation(id){
            if(confirm('Are you sure?')){
                var g =this;
                $.post(marketing + "/operation-delete", {
                jwt: this.user.jwt,
                id: id
                }).then(function(a){
                    location.reload()
                })
            }
        }
    }
}
</script>

<style>

</style>